import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './ContractCard.css';
import { ReactComponent as Contracts_Express } from '../../../assets/svg/Contratos_Express.svg';
import { ReactComponent as Vsg_AllRight } from '../../../assets/svg/Vector_AllRight.svg';
import Calendar from './Calendar';

export const ContractCard = () => {
  const stripePromise = loadStripe(
    'pk_test_51PgHFCAeLzigu3aEX8ofkjtLAJ9r5XAIvih7L3ng39FiQ0sDFeJyy80ufi2o2HjeGhxWar8MSCiKv8lsp0NDKrxQ00OOE5ykdE'
  );
  const { id } = useParams();
  const [dataTemplate, setDataTemplate] = useState({}); // Obtiene la data central del contrato
  const [, setVariables] = useState([]); // Contiene las variables del contrato "[variable]"
  const [contractVariables, setContractVariables] = useState({
    datesAndSignatures: {
      ['fecha actual']: new Date(),
    },
  }); // Crea un obj reactivo con las variables extraidas
  const [controllator, setControllator] = useState(1); // Controla el estado para llenar los inputs
  const [selectedOption, setSelectedOption] = useState('yes'); // Estado para controlar el checkbox de clausulas

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    const fetchContractTemplate = async () => {
      try {
        const response = await axios.get(
          `http://localhost:3000/api/templates/${id}`
        );
        const template = response.data;
        setDataTemplate(template);

        if (template.content) {
          const extractedVariables = [
            ...new Set(
              template.content.match(/\[([^\]]+)\]/g).map((v) => v.slice(1, -1))
            ),
          ];
          setVariables(extractedVariables);

          // Organize variables por categoria
          const varFiltredUser1 = extractedVariables.filter((v) =>
            v.includes('1')
          );
          const varFiltredUser2 = extractedVariables.filter((v) =>
            v.includes('2')
          );
          const clausesVars = extractedVariables.filter((v) =>
            v.includes('Cláusula')
          );
          const datesAndSignaturesVars = extractedVariables.filter(
            (v) =>
              v.includes('fecha') ||
              // v.includes('FECHA') ||
              v.includes('firma') ||
              // v.includes('FIRMA') ||
              v.includes('vigencia') ||
              v.includes('VIGENCIA') ||
              v.includes('ENTIDAD FEDERATIVA')
          );

          const initialVariables = {
            persona1: varFiltredUser1.reduce((acc, variable) => {
              acc[variable] = '';
              return acc;
            }, {}),
            persona2: varFiltredUser2.reduce((acc, variable) => {
              acc[variable] = '';
              return acc;
            }, {}),
            clauses: clausesVars.reduce((acc, variable) => {
              acc[variable] = '';
              return acc;
            }, {}),
            datesAndSignatures: datesAndSignaturesVars.reduce(
              (acc, variable) => {
                acc[variable] = '';
                return acc;
              },
              {}
            ),
          };

          setContractVariables(initialVariables);
        }
      } catch (error) {
        console.error('Error fetching contract template:', error);
      }
    };
    fetchContractTemplate();
  }, [id]);

  const renderContract = () => {
    let renderedTemplate = dataTemplate.content;

    // Reemplazar variables en todo el contrato
    Object.keys(contractVariables).forEach((category) => {
      Object.keys(contractVariables[category]).forEach((variable) => {
        const regex = new RegExp(`\\[${variable}\\]`, 'g');
        renderedTemplate = renderedTemplate.replace(
          regex,
          `<span class="variable-highlight">${
            contractVariables[category][variable] || `[${variable}]`
          }</span>`
        );
      });
    });

    // Aplicar primera censura entre palabras clave
    const startCensorship1 = 'SEGUNDA. DEFINICIONES';
    const endCensorship1 =
      'Confidencial para los fines establecidos en este Convenio';

    const startIdx1 = renderedTemplate.indexOf(startCensorship1);
    const endIdx1 = renderedTemplate.indexOf(
      endCensorship1,
      startIdx1 + startCensorship1.length
    );

    if (startIdx1 !== -1 && endIdx1 !== -1) {
      const beforeCensorship1 = renderedTemplate.slice(0, startIdx1);
      const toCensor1 = renderedTemplate.slice(
        startIdx1,
        endIdx1 + endCensorship1.length
      );
      const afterCensorship1 = renderedTemplate.slice(
        endIdx1 + endCensorship1.length
      );

      const censoredText1 = `<span class="blurred">${toCensor1}</span>`;

      renderedTemplate = `${beforeCensorship1}${censoredText1}${afterCensorship1}`;
    }

    // Aplicar segunda censura entre palabras clave
    const startCensorship2 = 'Independientemente de lo establecido';
    const endCensorship2 =
      'reclamar a su contraparte, compensación, contraprestación o gasto alguno.';

    const startIdx2 = renderedTemplate.indexOf(startCensorship2);
    const endIdx2 = renderedTemplate.indexOf(
      endCensorship2,
      startIdx2 + startCensorship2.length
    );

    if (startIdx2 !== -1 && endIdx2 !== -1) {
      const beforeCensorship2 = renderedTemplate.slice(0, startIdx2);
      const toCensor2 = renderedTemplate.slice(
        startIdx2,
        endIdx2 + endCensorship2.length
      );
      const afterCensorship2 = renderedTemplate.slice(
        endIdx2 + endCensorship2.length
      );

      const censoredText2 = `<span class="blurred">${toCensor2}</span>`;

      renderedTemplate = `${beforeCensorship2}${censoredText2}${afterCensorship2}`;
    }

    // Resaltar texto en mayúsculas
    renderedTemplate = renderedTemplate.replace(
      /\b(?!<span class="variable-highlight">)[A-ZÁÉÍÓÚÑÜ]{1,}\b(?!<\/span>)/g,
      (match) => `<span class="bold-text">${match}</span>`
    );

    return renderedTemplate;
  };

  const extractTextFromHtml = (html) => {
    const temporaryElement = document.createElement('div');
    temporaryElement.innerHTML = html;
    return temporaryElement.innerText || temporaryElement.textContent || '';
  };

  const handleCheckout = async () => {
    const stripe = await stripePromise;
    const numWithoutDot = Math.floor(parseFloat(dataTemplate.price) * 100);
    const token = localStorage.getItem('authToken');

    try {
      const htmlContract = renderContract();
      const plainTextContract = extractTextFromHtml(htmlContract);
      let createContractResponse;
      let createContractTemporalResponse;
      const paymentResponse = await axios.post(
        'http://localhost:3000/api/payments/create-payment',
        { name: dataTemplate.name, price: numWithoutDot },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      token
        ? (createContractResponse = await axios.post(
            'http://localhost:3000/api/contracts/',
            {
              session_id: paymentResponse.data.id,
              contractTemplateId: dataTemplate.id,
              name: dataTemplate.name,
              content: plainTextContract,
              type: dataTemplate.type,
              price: dataTemplate.price,
              paymentIntentId: null,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          ))
        : (createContractTemporalResponse = await axios.post(
            'http://localhost:3000/api/contracts-without-register/',
            {
              session_id: paymentResponse.data.id,
              contractTemplateId: dataTemplate.id,
              name: dataTemplate.name,
              content: plainTextContract,
              type: dataTemplate.type,
              price: dataTemplate.price,
              paymentIntentId: null,
            }
          ));

      if (
        (paymentResponse && createContractResponse) ||
        (paymentResponse && createContractTemporalResponse)
      ) {
        const result = await stripe.redirectToCheckout({
          sessionId: paymentResponse.data.id,
        });

        if (result.error) {
          console.error(result.error.message);
        }
      }
    } catch (error) {
      console.error('Error during checkout:', error);
    }
  };

  return (
    <section className="">
      <header className="header-Container w-[90%] mx-auto m-0 p-6 pt-10 pb-8">
        <h4 className="pb-6 text-primary-custom-text font-[600]">{`Edición de documentos - ${dataTemplate.name}`}</h4>
        <section className="flex justify-between">
          {[
            `Datos de la Persona 1`,
            'Datos de la Persona 2',
            'Cláusulas',
            'Firmas y Fechas',
            'Pago y Descarga',
          ].map((text, index) => (
            <div
              key={index}
              style={{
                backgroundColor:
                  controllator === index + 1 ? 'rgb(37 99 235)' : '#B0B0B0',
                width: controllator === index + 1 ? '30%' : '17.5%',
              }}
              className={`card-Controll-Title w-[20%] h-[9vh] text-white content-center text-center font-semibold ${index === 0 ? 'rounded-l' : index === 4 ? 'rounded-r' : ''}`}
            >
              {index + 1}.- {text}
            </div>
          ))}
        </section>
      </header>
      <div className="contract-Container pt-0">
        <section className="controller-Container flex flex-col items-end">
          <div className="controlator w-full">
            {controllator === 1 && (
              <>
                <h4 className="text-primary-custom-text font-[600]">{`Escribe tus datos aquí`}</h4>
                <section className="grid grid-cols-1 md:grid-cols-2 gap-x-4 w-[100%] mx-auto">
                  {Object.keys(contractVariables.persona1 || {}).map(
                    (variable, index) => (
                      <div key={index} className="form-group mx-auto">
                        <label>{variable.slice(0, -15)}:</label>
                        <input
                          type="text"
                          className="dynamic-input"
                          value={contractVariables.persona1[variable]}
                          placeholder={
                            variable.includes('DOMICILIO')
                              ? 'Calle Vicente #243'
                              : `INSERTE ${variable.slice(0, -15)}`
                          }
                          onChange={(e) => {
                            if (
                              e.target.attributes[2].value.includes('NOMBRE') ||
                              e.target.attributes[2].value.includes('INE')
                            ) {
                              return setContractVariables({
                                ...contractVariables,
                                persona1: {
                                  ...contractVariables.persona1,
                                  [variable]:
                                    e.target.value.toLocaleUpperCase(),
                                },
                              });
                            }
                            setContractVariables({
                              ...contractVariables,
                              persona1: {
                                ...contractVariables.persona1,
                                [variable]: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    )
                  )}
                </section>
              </>
            )}
            {controllator === 2 && (
              <>
                <h4 className="text-primary-custom-text font-[600]">{`Escribe tus datos aquí`}</h4>
                <section className="grid grid-cols-1 md:grid-cols-2 gap-x-4 w-[100%] mx-auto">
                  {Object.keys(contractVariables.persona2 || {}).map(
                    (variable, index) => (
                      <div key={index} className="form-group">
                        <label>{variable.slice(0, -15)}:</label>
                        <input
                          type="text"
                          className="dynamic-input"
                          value={contractVariables.persona2[variable]}
                          placeholder={
                            variable.includes('DOMICILIO')
                              ? 'Calle Vicente #243'
                              : `INSERTE ${variable.slice(0, -15)}`
                          }
                          onChange={(e) => {
                            if (
                              e.target.attributes[2].value.includes('NOMBRE') ||
                              e.target.attributes[2].value.includes('INE')
                            ) {
                              return setContractVariables({
                                ...contractVariables,
                                persona2: {
                                  ...contractVariables.persona2,
                                  [variable]:
                                    e.target.value.toLocaleUpperCase(),
                                },
                              });
                            }
                            setContractVariables({
                              ...contractVariables,
                              persona2: {
                                ...contractVariables.persona2,
                                [variable]: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    )
                  )}
                </section>
              </>
            )}
            {controllator === 3 && (
              <>
                <section className="form-clauses">
                  <div className="flex flex-col">
                    <h4 className="text-primary-custom-text font-[600]">
                      Editar Cláusulas
                    </h4>
                    <section className="section-edit-clauses w-[40%] mx-auto flex justify-between items-start">
                      <div className="clauses-label flex flex-col items-center w-[50%]">
                        <p>Cláusulas predeterminadas</p>
                        <section className="flex">
                          <label>
                            <input
                              disabled
                              type="checkbox"
                              checked={selectedOption === 'yes'}
                              onChange={() => setSelectedOption('yes')}
                            />
                            Sí
                          </label>

                          <label style={{ marginLeft: '20px' }}>
                            <input
                              disabled
                              type="checkbox"
                              checked={selectedOption === 'no'}
                              onChange={() => setSelectedOption('no')}
                            />
                            No
                          </label>
                        </section>
                      </div>
                      <div className="button-edit-clauses w-[40%]">
                        <button
                          className="w-full rounded-lg h-[6vh] bg-[#f1f1f1] shadow-[0_6px_10px_0px_rgba(176,176,176,0.69)] border-none"
                          disabled
                        >
                          Editar Cláusulas
                        </button>
                      </div>
                    </section>
                  </div>
                </section>
              </>
            )}
            {controllator === 4 && (
              <>
                <h4 className="text-primary-custom-text font-[600]">{`Escribe tus datos aquí`}</h4>
                <section className="form-section">
                  {Object.keys(contractVariables.datesAndSignatures || {}).map(
                    (variable, index) =>
                      variable.includes('vigencia') ||
                      variable.includes('VIGENCIA') ? (
                        <div className="form-group" key={index}>
                          <label
                            htmlFor="duracion"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {variable}:
                          </label>
                          <select
                            id="duracion"
                            name="duracion"
                            className="dynamic-input"
                            onChange={(e) =>
                              setContractVariables({
                                ...contractVariables,
                                datesAndSignatures: {
                                  ...contractVariables.datesAndSignatures,
                                  [variable]: `${e.target.value}`,
                                },
                              })
                            }
                            value={
                              contractVariables.datesAndSignatures[
                                'PERIODO DE VIGENCIA'
                              ]
                            }
                          >
                            <option value={`PERIODO DE VIGENCIA ${' '}`}>
                              INSERTA EL PERIODO DE VIGENCIA
                            </option>
                            <option value="1 año">1 año</option>
                            <option value="2 años">2 años</option>
                            <option value="3 años">3 años</option>
                            <option value="4 años">4 años</option>
                            <option value="5 años">5 años</option>
                            <option value="6 años">6 años</option>
                            <option value="7 años">7 años</option>
                            <option value="8 años">8 años</option>
                            <option value="9 años">9 años</option>
                            <option value="10 años">10 años</option>
                            <option value="indefinido">Indefinido</option>
                          </select>
                        </div>
                      ) : variable.includes('ENTIDAD FEDERATIVA') &&
                        !/\d/.test(variable) ? (
                        <div className="form-group" key={index}>
                          <label
                            htmlFor="estado"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {variable}:
                          </label>
                          <select
                            id="estado"
                            name="estado"
                            className="dynamic-input"
                            onChange={(e) =>
                              setContractVariables({
                                ...contractVariables,
                                datesAndSignatures: {
                                  ...contractVariables.datesAndSignatures,
                                  [variable]: `${e.target.value}`,
                                },
                              })
                            }
                          >
                            <option value="Aguascalientes">
                              Aguascalientes
                            </option>
                            <option value="Baja California">
                              Baja California
                            </option>
                            <option value="Baja California Sur">
                              Baja California Sur
                            </option>
                            <option value="Campeche">Campeche</option>
                            <option value="Chiapas">Chiapas</option>
                            <option value="Chihuahua">Chihuahua</option>
                            <option value="Coahuila">Coahuila</option>
                            <option value="Colima">Colima</option>
                            <option value="Durango">Durango</option>
                            <option value="Guanajuato">Guanajuato</option>
                            <option value="Guerrero">Guerrero</option>
                            <option value="Hidalgo">Hidalgo</option>
                            <option value="Jalisco">Jalisco</option>
                            <option value="México">Estado de México</option>
                            <option value="Michoacán">Michoacán</option>
                            <option value="Morelos">Morelos</option>
                            <option value="Nayarit">Nayarit</option>
                            <option value="Nuevo León">Nuevo León</option>
                            <option value="Oaxaca">Oaxaca</option>
                            <option value="Puebla">Puebla</option>
                            <option value="Querétaro">Querétaro</option>
                            <option value="Quintana Roo">Quintana Roo</option>
                            <option value="San Luis Potosí">
                              San Luis Potosí
                            </option>
                            <option value="Sinaloa">Sinaloa</option>
                            <option value="Sonora">Sonora</option>
                            <option value="Tabasco">Tabasco</option>
                            <option value="Tamaulipas">Tamaulipas</option>
                            <option value="Tlaxcala">Tlaxcala</option>
                            <option value="Veracruz">Veracruz</option>
                            <option value="Yucatán">Yucatán</option>
                            <option value="Zacatecas">Zacatecas</option>
                          </select>
                        </div>
                      ) : (
                        variable.includes('fecha') && (
                          <Calendar
                            variable={variable}
                            setContractVariables={setContractVariables}
                            contractVariables={contractVariables}
                            key={index}
                          />
                          // <div key={index} className="form-group">
                          //   <label>{variable.toUpperCase()}:</label>
                          //   <input
                          //     type="text"
                          //     className="dynamic-input"
                          //     value={
                          //       contractVariables.datesAndSignatures[variable]
                          //     }
                          //     placeholder={
                          //       variable.includes('fecha' || 'Fecha')
                          //         ? 'Ej: 30 de Septiembre de 2024'
                          //         : variable
                          //     }
                          //     onChange={(e) =>
                          //       setContractVariables({
                          //         ...contractVariables,
                          //         datesAndSignatures: {
                          //           ...contractVariables.datesAndSignatures,
                          //           [variable]: e.target.value,
                          //         },
                          //       })
                          //     }
                          //     style={{ width: 'auto', marginRight: '10px' }}
                          //   />
                          // </div>
                        )
                      )
                  )}
                </section>
              </>
            )}
            {controllator === 5 && (
              <div className="payment-Container flex justify-between bg-primary-custom-text h-[20vh] mb-14 rounded-lg">
                <Vsg_AllRight className="payment_Logo_L h-[20vh]" />
                <section className="flex flex-col justify-evenly items-start">
                  <div style={{ color: '#4CCCDE' }} className="">
                    Tu documento esta casi listo
                  </div>
                  <div className="payment-Redirection">
                    Podrás descargarlo despúes de{' '}
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        return handleCheckout();
                      }}
                      className="text-white"
                    >
                      completar tu compra
                    </a>
                  </div>
                </section>
                <Contracts_Express className="payment_Logo_R h-[25vh]" />
              </div>
            )}
          </div>
          <section className="Buttons-Container flex justify-between w-[30%] pb-2">
            <button
              onClick={() =>
                controllator > 1 && setControllator(controllator - 1)
              }
              className="btn-after-before bg-primary-custom-text p-4"
            >
              Volver
            </button>
            <button className="btn-save" disabled>
              Guardar
            </button>
            <button
              onClick={() =>
                controllator < 5
                  ? setControllator(controllator + 1)
                  : handleCheckout()
              }
              className="btn-after-before bg-primary-custom-text p-4"
            >
              Siguiente
            </button>
          </section>
        </section>
        {dataTemplate.content ? (
          <div className="contract-section bg-blue-300">
            <div
              className="contract-content"
              dangerouslySetInnerHTML={{
                __html: renderContract().replace(/\n/g, '<br />'),
              }}
            />
          </div>
        ) : (
          <div className="contract-section bg-blue-300">
            <span className="border border-blue-300 shadow bg-[#172b8545] h-[auto] rounded-md p-4 w-full mx-auto">
              <section className="animate-pulse flex space-x-4 flex-col items-center">
                <div className="rounded-full bg-slate-700 h-10 w-10 mb-4"></div>
                <section className="flex-1 space-y-6 py-1">
                  <div className="h-2 bg-slate-700 rounded"></div>
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                      <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                    </div>
                    <div className="h-2 bg-slate-700 rounded"></div>
                  </div>
                </section>
              </section>
            </span>
          </div>
        )}
      </div>
    </section>
  );
};
