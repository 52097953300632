import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import {
  CheckCircle2,
  FileEdit,
  Share2,
  FileText,
  UserPlus,
  Building2,
  ChevronRight,
  Shield,
  BadgeCheck,
} from 'lucide-react';
import Hero from './Hero';
import chanyVentures from '../../assets/conference/CHVentures-Logo.jpg';
import { NavLink } from 'react-router-dom';

const contracts = [
  {
    title: 'Contrato de Confidencialidad (NDA)',
    description:
      'Protege tu información sensible y establece términos claros de confidencialidad',
    fullDescription: `Un documento de confidencialidad, también conocido como acuerdo de confidencialidad, es un contrato legal entre dos o más partes en el que se establecen los términos y condiciones para proteger la información confidencial que se comparte entre ellas.

El documento de confidencialidad define qué se considera información confidencial, establece las responsabilidades y obligaciones de las partes en relación con dicha información, y generalmente incluye disposiciones sobre la duración del acuerdo, las excepciones a la confidencialidad, las consecuencias por incumplimiento y la jurisdicción aplicable en caso de disputas.

Es una herramienta legal diseñada para proteger la privacidad y la propiedad intelectual de las partes involucradas al compartir información sensible durante el curso de una relación comercial, laboral u otra interacción.`,
  },
  {
    title: 'Contrato de Prestación de Servicios',
    description: 'Define alcances, responsabilidades y términos de pago',
  },
  {
    title: 'Carta Poder',
    description: 'Autoriza legalmente a terceros para actuar en tu nombre',
  },
  {
    title: 'Contrato de Compra Venta',
    description:
      'Formaliza la transferencia de bienes o servicios entre las partes involucradas',
  },
  {
    title: 'Aviso de Privacidad',
    description: 'Cumple con la legislación de protección de datos personales',
  },
  {
    title: 'Términos y Condiciones',
    description: 'Establece las reglas y políticas de uso de tu servicio',
  },
];

const benefits = [
  {
    icon: CheckCircle2,
    title: 'Asesoría Legal Personalizada',
    description:
      'Obtén apoyo experto en cada etapa de tu contrato para asegurar el cumplimiento normativo.',
  },
  {
    icon: FileText,
    title: 'Accesibilidad Total',
    description:
      'Plantillas legales optimizadas para diversos sectores, listas para usar en minutos.',
  },
  {
    icon: Shield,
    title: 'Seguridad de Datos',
    description:
      'Protege la confidencialidad de tus documentos con encriptación avanzada.',
  },
  {
    icon: Share2,
    title: 'Optimiza Tiempo y Recursos',
    description:
      'Automatiza la gestión de contratos y enfócate en lo que más importa: hacer crecer tu negocio.',
  },
];

const howItWorks = [
  {
    icon: FileText,
    title: 'Elige tu Plantilla Ideal',
    description:
      'Accede a una amplia variedad de contratos diseñados para diferentes necesidades e industrias.',
  },
  {
    icon: FileEdit,
    title: 'Edita de Forma Sencilla',
    description:
      'Ajusta y personaliza los contratos con una interfaz intuitiva, sin complicaciones legales.',
  },
  {
    icon: Share2,
    title: 'Firma y Comparte',
    description:
      'Genera un contrato listo para firmar y enviar digitalmente en cuestión de minutos.',
  },
];

const contractFeatures = [
  {
    icon: UserPlus,
    title: 'Para Freelancers',
    description:
      'Protege tus acuerdos con contratos sencillos y seguros. Dedica más tiempo a tu trabajo y menos a los trámites.',
  },
  {
    icon: Building2,
    title: 'Para Emprendedores',
    description:
      'Formaliza tu negocio con confianza desde el primer día, asegurando alianzas clave y cubriendo cada detalle legal.',
  },
  {
    icon: BadgeCheck,
    title: 'Para PYMEs',
    description:
      'Simplifica la gestión legal de tu empresa con plantillas profesionales y ahorra en costos operativos.',
  },
];

const cardVariants = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.1,
      duration: 0.5,
      ease: 'easeOut',
    },
  }),
};

export default function LandingPage() {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-white via-primary-50 to-accent-purple/5">
      <Hero />

      {/* Benefits Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center gradient-text mb-12">
            ¿Por qué elegir Contratos Express?
          </h2>
          <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
            {benefits.map((benefit, index) => (
              <motion.div
                key={index}
                variants={cardVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                custom={index}
                className="feature-card glass-effect p-6 rounded-xl hover-lift"
              >
                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0">
                    <benefit.icon className="h-8 w-8 text-accent-purple" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-primary-500 mb-2">
                      {benefit.title}
                    </h3>
                    <p className="text-gray-600">{benefit.description}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* How it Works Section */}
      <section className="py-20 bg-gradient-to-br from-primary-50 via-white to-accent-purple/5">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center gradient-text mb-12">
            Cómo Funcionamos
          </h2>
          <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
            {howItWorks.map((step, index) => (
              <motion.div
                key={index}
                variants={cardVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                custom={index}
                className="glass-effect p-6 rounded-xl text-center hover-lift"
              >
                <div className="bg-gradient-to-br from-primary-500 to-accent-purple p-3 rounded-full w-16 h-16 mx-auto mb-4 flex items-center justify-center">
                  <step.icon className="h-8 w-8 text-white" />
                </div>
                <h3 className="text-xl font-semibold text-primary-500 mb-2">
                  {step.title}
                </h3>
                <p className="text-gray-600">{step.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Contract Features Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center gradient-text mb-12">
            Soluciones para cada necesidad
          </h2>
          <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
            {contractFeatures.map((feature, index) => (
              <motion.div
                key={index}
                variants={cardVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                custom={index}
                className="feature-card glass-effect p-6 rounded-xl text-center hover-lift"
              >
                <div className="bg-gradient-to-br from-accent-purple to-accent-indigo p-3 rounded-full w-16 h-16 mx-auto mb-4 flex items-center justify-center">
                  <feature.icon className="h-8 w-8 text-white" />
                </div>
                <h3 className="text-xl font-semibold gradient-text mb-2">
                  {feature.title}
                </h3>
                <p className="text-gray-600">{feature.description}</p>
              </motion.div>
            ))}
          </div>
          <div className="text-center mt-12">
            <p className="text-gray-600 animate-pulse-slow">
              Únete a miles de profesionales que ya simplifican sus operaciones
              legales con nosotros.
            </p>
          </div>
        </div>
      </section>

      {/* Contracts Catalog */}
      <section
        id="catalogo"
        className="py-20 bg-gradient-to-br from-primary-50 via-white to-accent-purple/5"
      >
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center gradient-text mb-6">
            Proximamente
          </h2>
          <h2 className="text-3xl font-bold text-center gradient-text mb-12">
            Catálogo de Contratos
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
            {contracts.map((contract, index) => (
              <motion.div
                key={index}
                variants={cardVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                custom={index}
                className="glass-effect p-6 rounded-xl hover-lift"
              >
                <h3 className="text-xl font-semibold text-primary-500 mb-3">
                  {contract.title}
                </h3>
                <p className="text-gray-600 mb-4">{contract.description}</p>
                <NavLink
                  // to={`/template/${index + 1}/${contract.title}`}
                  to={`/`}
                  className="inline-flex items-center text-accent-purple hover:text-accent-indigo font-medium transition-colors group"
                >
                  Generar contrato
                  <ChevronRight className="ml-1 h-5 w-5 transform group-hover:translate-x-1 transition-transform" />
                </NavLink>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Supported By Section */}
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl font-bold text-center gradient-text mb-12">
            Apoyados por
          </h2>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="flex justify-center items-center"
          >
            <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
              <img
                src={chanyVentures}
                alt="Chany Ventures Logo"
                className="h-24 w-auto"
              />
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
}
