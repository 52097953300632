/* eslint-disable no-unused-vars */
import React, { createContext, useState } from 'react';

const ContractContext = createContext();
const ContractProvider = ({ children }) => {
  const [contract, setContract] = useState();

  const getContractTemplate = (contract) => {
    setContract(contract);
    console.log(contract);
  };

  const data = {
    contract,
    getContractTemplate,
  };

  return (
    <ContractContext.Provider value={data}>{children}</ContractContext.Provider>
  );
};
export { ContractProvider };
export default ContractContext;
