import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './ContractCard.css';

function Calendar({ variable, setContractVariables, contractVariables }) {
  const selectedDate = contractVariables.datesAndSignatures[variable]
    ? new Date(contractVariables.datesAndSignatures[variable]) // Convierte a Date si existe
    : null;

  return (
    <div className="form-group flex items-center">
      <label className="text-lg font-semibold w-[30%] mr-3">
        {variable.toUpperCase()}:
      </label>
      <DatePicker
        selected={selectedDate} // Pasa selectedDate aquí, que es un objeto Date
        onChange={(date) => {
          setContractVariables({
            ...contractVariables,
            datesAndSignatures: {
              ...contractVariables.datesAndSignatures,
              [variable]: date ? date.toDateString() : null, // Guarda como ISO para ser compatible
            },
          });
        }}
        dateFormat="dd/MM/yy"
        placeholderText="Elige una fecha"
      />
    </div>
  );
}

export default Calendar;
