import React from 'react';
import {
  FileText,
  Clock,
  CheckCircle,
  AlertCircle,
  ChevronRight,
  Download,
  Edit,
} from 'lucide-react';

const contracts = {
  inProgress: [
    {
      id: 1,
      title: 'Contrato de Confidencialidad',
      progress: 60,
      lastEdited: '2024-03-15',
      dueDate: '2024-03-30',
    },
    {
      id: 2,
      title: 'Contrato de Servicios',
      progress: 30,
      lastEdited: '2024-03-14',
      dueDate: '2024-03-28',
    },
  ],
  completed: [
    {
      id: 3,
      title: 'Contrato Laboral',
      completedDate: '2024-03-10',
      downloadCount: 2,
    },
    {
      id: 4,
      title: 'Acuerdo de Prestación de Servicios',
      completedDate: '2024-03-05',
      downloadCount: 1,
    },
  ],
  purchased: [
    {
      id: 5,
      title: 'Contrato de Arrendamiento',
      purchaseDate: '2024-03-01',
      status: 'sin_iniciar',
    },
    {
      id: 6,
      title: 'Contrato de Compraventa',
      purchaseDate: '2024-03-12',
      status: 'sin_iniciar',
    },
  ],
};

function ContractsDashboard() {
  return (
    <div className="space-y-6">
      {/* Header Stats */}
      <div className="grid grid-cols-3 gap-6">
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-500">En Progreso</p>
              <p className="text-2xl font-bold text-gray-900">
                {contracts.inProgress.length}
              </p>
            </div>
            <Clock className="h-8 w-8 text-blue-500" />
          </div>
        </div>
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-500">Completados</p>
              <p className="text-2xl font-bold text-gray-900">
                {contracts.completed.length}
              </p>
            </div>
            <CheckCircle className="h-8 w-8 text-emerald-500" />
          </div>
        </div>
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-500">Contratos Comprados</p>
              <p className="text-2xl font-bold text-gray-900">
                {contracts.purchased.length}
              </p>
            </div>
            <FileText className="h-8 w-8 text-purple-500" />
          </div>
        </div>
      </div>

      {/* In Progress Contracts */}
      <div className="bg-white rounded-lg shadow">
        <div className="p-6">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">
            Contratos en Progreso
          </h2>
          <div className="space-y-4">
            {contracts.inProgress.map((contract) => (
              <div
                key={contract.id}
                className="border border-gray-200 rounded-lg p-4"
              >
                <div className="flex items-center justify-between mb-3">
                  <div className="flex items-center space-x-3">
                    <Clock className="h-5 w-5 text-blue-500" />
                    <h3 className="font-medium text-gray-900">
                      {contract.title}
                    </h3>
                  </div>
                  <button className="text-blue-600 hover:text-blue-700 flex items-center">
                    Continuar <ChevronRight className="h-4 w-4 ml-1" />
                  </button>
                </div>
                <div className="mb-2">
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div
                      className="bg-blue-500 rounded-full h-2"
                      style={{ width: `${contract.progress}%` }}
                    ></div>
                  </div>
                </div>
                <div className="flex justify-between text-sm text-gray-500">
                  <span>Última edición: {contract.lastEdited}</span>
                  <span>Vence: {contract.dueDate}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Completed Contracts */}
      <div className="bg-white rounded-lg shadow">
        <div className="p-6">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">
            Contratos Completados
          </h2>
          <div className="space-y-4">
            {contracts.completed.map((contract) => (
              <div
                key={contract.id}
                className="border border-gray-200 rounded-lg p-4"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-3">
                    <CheckCircle className="h-5 w-5 text-emerald-500" />
                    <h3 className="font-medium text-gray-900">
                      {contract.title}
                    </h3>
                  </div>
                  <div className="flex items-center space-x-3">
                    <button className="text-gray-600 hover:text-blue-600 flex items-center">
                      <Edit className="h-4 w-4" />
                    </button>
                    <button className="text-emerald-600 hover:text-emerald-700 flex items-center">
                      <Download className="h-4 w-4" />
                    </button>
                  </div>
                </div>
                <div className="mt-2 text-sm text-gray-500">
                  <span>Completado: {contract.completedDate}</span>
                  <span className="mx-2">•</span>
                  <span>Descargas: {contract.downloadCount}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Purchased Contracts */}
      <div className="bg-white rounded-lg shadow">
        <div className="p-6">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">
            Contratos Comprados
          </h2>
          <div className="space-y-4">
            {contracts.purchased.map((contract) => (
              <div
                key={contract.id}
                className="border border-gray-200 rounded-lg p-4"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-3">
                    <AlertCircle className="h-5 w-5 text-purple-500" />
                    <h3 className="font-medium text-gray-900">
                      {contract.title}
                    </h3>
                  </div>
                  <button className="bg-purple-100 text-purple-600 px-4 py-1 rounded-full text-sm hover:bg-purple-200">
                    Comenzar
                  </button>
                </div>
                <div className="mt-2 text-sm text-gray-500">
                  <span>Comprado: {contract.purchaseDate}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContractsDashboard;
