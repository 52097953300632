import React, { useState } from 'react';
import {
  Bell,
  Settings,
  LogOut,
  Search,
  Upload,
  Send,
  MessageSquare,
  FileText,
  Users,
  PenTool,
  History,
  FolderOpen,
  AlertCircle,
  Plus,
  User,
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import ContractsDashboard from './Components/ContractsPanel';
import SignDocumentsDashboard from './Components/DocumentSearch';
import UserMenu from './Components/FileUpload';

export default function DashboardPage({ userEmail, onLogout }) {
  const [message, setMessage] = useState('');
  const [activeSection, setActiveSection] = useState('chat');
  const navigate = useNavigate();

  const handleLogout = () => {
    onLogout();
    navigate('/');
  };

  const menuItems = [
    { id: 'chat', icon: MessageSquare, label: 'Chat IA' },
    { id: 'contracts', icon: FileText, label: 'Mis Contratos' },
    { id: 'sign', icon: PenTool, label: 'Firmar Documentos' },
    { id: 'history', icon: History, label: 'Historial' },
    { id: 'shared', icon: FolderOpen, label: 'Compartidos' },
    { id: 'users', icon: Users, label: 'Usuarios' },
  ];

  const renderContent = () => {
    switch (activeSection) {
      case 'contracts':
        return <ContractsDashboard />;
      case 'sign':
        return <SignDocumentsDashboard />;
      case 'chat':
        return (
          <div className="grid grid-cols-2 gap-6">
            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-lg font-semibold mb-4">Chat con IA</h2>
              <div className="h-64 border border-gray-200 rounded-lg p-4 mb-4 overflow-y-auto">
                <div className="flex items-start mb-4">
                  <div className="bg-blue-100 rounded-lg p-3 max-w-[80%]">
                    <p className="text-sm text-gray-800">
                      ¡Hola! Soy tu asistente legal. Sube un documento para
                      comenzar el análisis.
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex space-x-2">
                <input
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Escribe tu mensaje..."
                  className="flex-1 border border-gray-200 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button className="bg-blue-600 text-white p-2 rounded-lg hover:bg-blue-700">
                  <Send className="h-5 w-5" />
                </button>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-lg font-semibold mb-4">Subir Documento</h2>
              <div className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center">
                <Upload className="h-12 w-12 mx-auto text-gray-400 mb-4" />
                <p className="text-gray-600 mb-2">
                  Arrastra un archivo o haz clic para seleccionar
                </p>
                <button className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700">
                  Analizar Documento
                </button>
              </div>
              <div className="mt-6">
                <h3 className="text-sm font-medium text-gray-700 mb-2">
                  Buscar en Documentos
                </h3>
                <div className="space-y-3">
                  <div className="flex items-center justify-between p-3 border border-gray-200 rounded-lg hover:bg-gray-50">
                    <div>
                      <h4 className="font-medium text-gray-900">
                        Contrato Laboral - Dev Senior
                      </h4>
                    </div>
                    <button className="text-blue-600 hover:text-blue-700">
                      +
                    </button>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-gray-200 rounded-lg hover:bg-gray-50">
                    <div>
                      <h4 className="font-medium text-gray-900">
                        Acuerdo de Confidencialidad
                      </h4>
                    </div>
                    <button className="text-blue-600 hover:text-blue-700">
                      +
                    </button>
                  </div>
                  <div className="flex items-center justify-between p-3 border border-gray-200 rounded-lg hover:bg-gray-50">
                    <div>
                      <h4 className="font-medium text-gray-900">
                        Contrato de Servicios TI
                      </h4>
                    </div>
                    <button className="text-blue-600 hover:text-blue-700">
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className="flex items-center justify-center h-full">
            <p className="text-gray-500">Sección en desarrollo</p>
          </div>
        );
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="bg-white shadow-sm">
        <div className="flex items-center justify-between px-6 py-4">
          <div className="flex items-center space-x-2">
            <div className="text-blue-600 font-bold text-xl">
              Contratos Express
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <button className="p-2 text-gray-500 hover:text-blue-600 relative">
              <Bell className="h-6 w-6" />
              <span className="absolute top-0 right-0 h-2 w-2 bg-red-500 rounded-full"></span>
            </button>
            <button className="p-2 text-gray-500 hover:text-blue-600">
              <Settings className="h-6 w-6" />
            </button>
            <UserMenu userEmail={userEmail} onLogout={onLogout} />
          </div>
        </div>
      </header>

      <div className="flex mx-auto">
        <aside className="w-64 bg-white border-r border-gray-200 p-6">
          <nav className="space-y-2">
            {menuItems.map((item) => (
              <button
                key={item.id}
                onClick={() => setActiveSection(item.id)}
                className={`w-full flex items-center space-x-3 px-4 py-2 rounded-lg transition-colors border-none ${
                  activeSection === item.id
                    ? 'bg-gray-200 text-blue-600'
                    : 'bg-gray-50 hover:text-blue-600 hover:cursor-pointer focus:border-transparent hover:border-none'
                }`}
              >
                <item.icon className="h-5 w-5" />
                <span>{item.label}</span>
              </button>
            ))}
          </nav>
        </aside>

        <main className="flex-1 p-6">{renderContent()}</main>
      </div>
    </div>
  );
}
