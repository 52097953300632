import React from 'react';
import { motion } from 'framer-motion';
import { ChevronRight } from 'lucide-react';
import { ReactComponent as WhatsApp } from '../../assets/conference/whatsapp.svg';

export default function Hero() {
  return (
    <section className="pt-32 pb-20 bg-gradient-to-br from-white via-primary-50 to-accent-purple/5">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto text-center">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-6xl font-bold gradient-text mb-6"
          >
            Simplifica, ahorra y confía en cada palabra
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="text-xl text-gray-600 mb-8"
          >
            Empoderamos tu negocio con contratos claros, rápidos y accesibles.
            No importa si estás en México o en cualquier rincón del mundo,
            obtendrás documentos legales personalizados en minutos, respaldados
            por expertos.
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="flex flex-col sm:flex-row justify-center gap-4"
          >
            <motion.button
              onClick={() => {
                const catalogSection = document.getElementById('catalogo');
                catalogSection?.scrollIntoView({ behavior: 'smooth' });
              }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="animated-gradient text-white px-8 py-3 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 flex items-center justify-center group border-none"
            >
              Catálogo de Contratos
              <ChevronRight className="ml-2 h-5 w-5 transform group-hover:translate-x-1 transition-transform" />
            </motion.button>
            <a
              href="https://wa.me/message/52YGG6B6M7W5C1"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center border border-green-500 text-green-500 px-6 py-2 rounded-lg hover:bg-green-50 transition"
            >
              <WhatsApp className="h-5 w-5 mr-2" />
              <p>Contáctanos</p>
            </a>
          </motion.div>
        </div>
      </div>
    </section>
  );
}
