import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const TermsAndConditions = () => {
  // Al montar el componente, se hace scroll al principio de la página
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  // Lista de secciones con títulos y contenidos de cada una
  const sections = [
    {
      title: 'Introducción.',
      content:
        'Los presentes Términos y Condiciones (en adelante se denominarán como “los Términos”) regulan el acceso y uso del sitio web https://contratos-express.com/ (en adelante se denominará como “el Sitio”), así como los servicios proporcionados por Contratos Express, S. de R.L. de C.V. (en adelante, “el Prestador”). Al acceder y utilizar el Sitio, aceptas cumplir con estos Términos en su totalidad.',
    },
    {
      title: 'Aceptación de los Términos.',
      content:
        'Al utilizar el Sitio y los servicios proporcionados por Contratos Express, S. de R.L. de C.V., el usuario (en adelante, “el Usuario” o “el Cliente”) acepta estar sujeto a los presentes Términos. Si no está de acuerdo con alguno de los Términos, deberá abstenerse de utilizar el Sitio o los servicios.',
    },
    {
      title: 'Objeto de los Servicios.',
      content:
        'Contratos Express, S. de R.L. de C.V. ofrece una plataforma digital que facilita la creación, autollenado y revisión de contratos y plantillas legales. Los documentos generados a través del Sitio son creados con base en la información proporcionada por el Usuario. Dichos documentos podrán descargarse, y su uso será responsabilidad exclusiva del Usuario. Contratos Express no provee asesoría legal directa ni sustituye el consejo de un abogado calificado.',
    },
    {
      title: 'Productos o Servicios.',
      content:
        'Todos los productos y servicios ofrecidos a través del Sitio son exclusivamente digitales. Contratos Express, S. de R.L. de C.V. se reserva el derecho de modificar las descripciones de los productos, servicios y precios sin previo aviso. El uso de los productos generados será responsabilidad del Usuario.',
    },
    {
      title: 'Propiedad Intelectual.',
      content: `Todo el contenido, diseño, software y propiedad intelectual asociados
          al Sitio y los documentos generados son propiedad de Contratos
          Express, S.de R.L.de C.V.El Usuario tiene una licencia limitada
          para usar los documentos únicamente para fines personales o
          comerciales, pero no podrá reproducir, distribuir ni modificar dichos
          documentos sin el consentimiento expreso de Contratos Express, S.de
          R.L.de C.V.`,
    },
    {
      title: ' Modificaciones y Suspensión del Servicio.',
      content: ` El Prestador se reserva el derecho de modificar, interrumpir o
          suspender el acceso a los servicios en cualquier momento y sin previo
          aviso. El uso continuado del Sitio después de que se hayan realizado
          modificaciones constituirá la aceptación de los nuevos Términos por
          parte del Usuario.`,
    },
    {
      title: 'Responsabilidad del Prestador.',
      content: `Contratos Express, S. de R.L. de C.V. no garantiza la exactitud o
          idoneidad de los documentos generados para casos específicos. El
          Usuario asume toda responsabilidad por el uso de los documentos y por
          las consecuencias legales que puedan derivarse de su utilización. En
          ningún caso Contratos Express, S. de R.L. de C.V. será responsable por
          daños directos, indirectos o consecuentes derivados del uso de los
          servicios o documentos.`,
    },
    {
      title: 'Responsabilidad del Usuario.',
      content: `El Usuario es responsable de la precisión y completitud de la
          información que proporciona al generar los contratos. Cualquier error,
          omisión o ajuste necesario en los documentos generados será de
          exclusiva responsabilidad del Usuario. Contratos Express, S. de R.L.
          de C.V. no se hace responsable de la edición, uso o mal uso posterior
          de los documentos descargados.`,
    },
    {
      title: ' Información de Facturación y Cuenta.',
      content: `          El Usuario es responsable de proporcionar información verídica de
          facturación precisa y de mantener actualizada su cuenta. Contratos
          Express, S. de R.L. de C.V. se reserva el derecho de rechazar
          cualquier pedido en caso de errores en la información proporcionada.`,
    },
    {
      title: 'Herramientas de Terceros.',
      content: `El Sitio puede ofrecer acceso a herramientas proporcionadas por
          terceros. El uso de dichas herramientas es a discreción del Usuario,
          quien acepta que el Prestador no tiene control ni responsabilidad
          sobre su funcionamiento. El Usuario libera a Contratos Express, S. de
          R.L. de C.V. de cualquier responsabilidad derivada del uso de estas
          herramientas.`,
    },
    {
      title: 'Enlaces a Sitios de Terceros.',
      content: `  El Sitio puede contener enlaces a otros sitios web de terceros.
          Contratos Express, S. de R.L. de C.V. no se responsabiliza por el
          contenido ni la seguridad de dichos sitios, y el acceso a los mismos
          será bajo responsabilidad exclusiva del Usuario.`,
    },
    {
      title: 'Prohibiciones.',
      content: `Está prohibido el uso del Sitio con fines ilegales, ofensivos o no
          autorizados. Se prohíbe estrictamente la violación de derechos de
          propiedad intelectual y la transmisión de virus o software malicioso.
          El incumplimiento de estas disposiciones puede resultar en la
          terminación del acceso al Sitio y en la reclamación de daños y
          perjuicios.`,
    },
    {
      title: 'Indemnización.',
      content: ` El Usuario acepta indemnizar y mantener indemne a Contratos Express,
          S. de R.L. de C.V. de cualquier reclamación, demanda o pérdida que
          surja del uso indebido del Sitio o por la violación de estos Términos.`,
    },
    {
      title: ' Jurisdicción y Ley Aplicable.',
      content: `Los presentes Términos se regirán e interpretarán de acuerdo con las
          leyes vigentes en México. Cualquier disputa que surja relacionada con
          los Términos será resuelta ante los tribunales competentes de la
          Ciudad de México.`,
    },
    {
      title: 'Privacidad y Protección de Datos.',
      content: `La recolección y uso de los datos personales de los usuarios se
          realizará conforme a lo dispuesto en el Aviso de Privacidad de
          Contratos Express, S. de R.L. de C.V., en cumplimiento con la Ley
          Federal de Protección de Datos Personales en Posesión de los
          Particulares.`,
    },
    {
      title: ' Modificaciones a los Términos.',
      content: `El Prestador se reserva el derecho de modificar estos Términos en
          cualquier momento. Las modificaciones entrarán en vigor al momento de
          su publicación en el Sitio, y será responsabilidad del Usuario revisar
          periódicamente los Términos para estar al tanto de las
          actualizaciones.`,
    },
    {
      title: 'Terminación.',
      content: `Contratos Express, S. de R.L. de C.V. puede, en cualquier momento,
          suspender o finalizar el acceso al Sitio sin responsabilidad alguna,
          por cualquier motivo, incluyendo el incumplimiento de los presentes
          Términos.`,
    },
  ];

  return (
    <div className="w-full flex flex-col mb-20">
      <header className="w-full py-24">
        <h2 className="text-3xl font-semibold text-center py-4 text-blue-800">
          TÉRMINOS Y CONDICIONES
        </h2>
      </header>

      <div className="w-4/5 mx-auto">
        {sections.map((section, index) => (
          <p key={index} className="mb-4">
            <span className="text-gray-700 font-bold">{section.title}</span>{' '}
            {section.content}
          </p>
        ))}
      </div>
      <footer className="w-full flex justify-center mt-10">
        <Link to="/" className="text-blue-700 font-medium underline">
          Volver al inicio
        </Link>
      </footer>
    </div>
  );
};

export default TermsAndConditions;
