import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as WhatsApp } from '../../assets/conference/whatsapp.svg';
import Logo from '../../assets/conference/LOGO.jpeg';
import UserMenu from './UserMenu';
import '../Home/Navbar.css';

const Navbar = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    setIsAuthenticated(!!token);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
    navigate('/');
  };

  return (
    <header className="fixed w-full bg-white shadow-sm z-50">
      <div className="container mx-auto px-4 py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <img
              className="h-8 w-8 text-[#1D4ED8]"
              src={Logo}
              alt="contratos-express"
            />
            <NavLink to="/" className="ml-2 text-xl font-bold text-gray-800">
              Contratos Express
            </NavLink>
          </div>

          <nav className="hidden md:flex items-center space-x-8">
            {isAuthenticated ? (
              <UserMenu onLogout={handleLogout} />
            ) : (
              <>
                <NavLink
                  to="/"
                  className="text-gray-600 hover:text-blue-600 transition"
                >
                  Iniciar Sesión
                </NavLink>
                <NavLink
                  to="/"
                  className="text-gray-600 hover:text-blue-600 transition"
                >
                  Registrarse
                </NavLink>
                <a
                  href="https://wa.me/message/52YGG6B6M7W5C1"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center border border-green-500 text-green-500 px-6 py-2 rounded-lg hover:bg-green-50 transition"
                >
                  <WhatsApp className="h-5 w-5 mr-2" />
                  <p>Contáctanos</p>
                </a>
              </>
            )}
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
