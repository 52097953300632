import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const UserProfile = () => {
  const [user, setUser] = useState({ contracts: [] });
  const [password, setPassword] = useState('');
  const [showEditForm, setShowEditForm] = useState(false);
  const navigate = useNavigate();

  const token = localStorage.getItem('authToken');

  useEffect(() => {
    if (!token) {
      navigate('/login');
    } else {
      // Fetch user profile
      axios
        .get('http://localhost:3000/api/users/profile', {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          setUser(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [token, navigate]);

  // Formatear fecha
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Toggle para mostrar el formulario de edición
  const handleEditInfo = () => {
    setShowEditForm(!showEditForm);
  };

  // Handle para actualizar los datos del usuario
  const handleUpdateProfile = () => {
    axios
      .put(
        'http://localhost:3000/api/users/update-user',
        {
          name: user.name,
          surname: user.surname,
          password: password,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(() => alert('Perfil actualizado exitosamente!'))
      .catch((err) => console.error(err));
  };

  // Handle para enviar una peticion de reestablecimiento de contraseña
  const handleChangePassword = () => {
    axios
      .post(
        'http://localhost:3000/api/auth/1/forgot-password',
        {
          email: user.email,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(() => {
        // Further steps for changing password with token from email
        alert('Solicitud de cambio de contraseña enviada a tu email');
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      <div className="container mx-auto p-6 text-gray-700 pt-32">
        <h1 className="text-3xl font-bold text-center text-blue-800 mb-6">
          Perfil del Usuario
        </h1>
        <div className="flex flex-col md:flex-row md:space-x-6">
          {/* Profile Information */}
          <div className="w-full md:w-1/2 p-4 border rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-4">
              Información Personal
            </h2>
            <p className="mb-2">
              <strong>Fecha de creación:</strong>{' '}
              {user.createdAt ? formatDate(user.createdAt).toUpperCase() : ''}
            </p>

            <p className="mb-2">
              <strong>Nombre:</strong>{' '}
              {user.createdAt ? user.name.toUpperCase() : ''}
            </p>

            <p className="mb-2">
              <strong>Apellido:</strong>{' '}
              {user.createdAt ? user.surname.toUpperCase() : ''}
            </p>

            <p className="mb-2">
              <strong>Correo:</strong>{' '}
              {user.createdAt ? user.email.toUpperCase() : ''}
            </p>

            <button
              onClick={handleEditInfo}
              className="mt-4 px-4 py-2 bg-blue-800 text-white rounded"
            >
              {showEditForm ? 'Cerrar Formulario' : 'Actualizar Información'}
            </button>

            {showEditForm && (
              <div className="mt-4 bg-gray-100 p-4 rounded-lg shadow-inner">
                <h3 className="text-xl font-bold mb-2">
                  Formulario de Actualización
                </h3>
                <form className="space-y-4">
                  <div>
                    <label className="block mb-1">Nombre:</label>
                    <input
                      type="text"
                      value={user.name}
                      onChange={(e) =>
                        setUser({ ...user, name: e.target.value })
                      }
                      className="w-full border border-gray-300 p-2 rounded"
                    />
                  </div>
                  <div>
                    <label className="block mb-1">Apellido:</label>
                    <input
                      type="text"
                      value={user.surname}
                      onChange={(e) =>
                        setUser({ ...user, surname: e.target.value })
                      }
                      className="w-full border border-gray-300 p-2 rounded"
                    />
                  </div>
                  <div>
                    <label className="block mb-1">Contraseña:</label>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-full border border-gray-300 p-2 rounded"
                    />
                  </div>
                  <button
                    type="button"
                    onClick={handleUpdateProfile}
                    className="mt-4 bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
                  >
                    Guardar Cambios
                  </button>
                </form>
              </div>
            )}
          </div>

          {/* Password Change */}
          <div className="w-full md:w-1/2 p-4 border rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-4">Cambiar Contraseña</h2>
            <button
              onClick={handleChangePassword}
              className="px-4 py-2 bg-blue-800 text-white rounded"
            >
              Enviar solicitud de cambio de contraseña
            </button>
          </div>
        </div>

        {/* Contracts Section */}
        <div className="mt-6">
          <h2 className="text-2xl font-semibold mb-4">Contratos Guardados</h2>
          {user.contracts.length ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {user.contracts.length > 0 ? (
                user.contracts.map((contract, index) => (
                  <div key={index} className="p-4 border rounded-lg shadow-md">
                    <h3 className="font-semibold text-blue-800">
                      {contract.name}
                    </h3>
                    <h5>{contract.type}</h5>
                    <h5
                      style={
                        contract.state === 'pending'
                          ? { color: 'red', fontWeight: 'bold' }
                          : { color: 'green', fontWeight: 'bold' }
                      }
                    >
                      {contract.state.toUpperCase()}
                    </h5>
                  </div>
                ))
              ) : (
                <p className="text-gray-600">No tienes contratos guardados.</p>
              )}
            </div>
          ) : (
            false
          )}
        </div>
      </div>
    </>
  );
};

export default UserProfile;
