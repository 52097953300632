import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <div className="w-full mb-20 px-4">
      <header className="w-full pt-28 pb-16">
        <h2 className="text-3xl font-semibold text-center py-4 text-blue-800">
          AVISO DE PRIVACIDAD
        </h2>
      </header>

      <section className="w-4/5 mx-auto">
        <p>
          En cumplimiento con lo dispuesto por los artículos 3, fracción I, 8,
          12, 15, 16 y demás aplicables de la Ley Federal de Protección de Datos
          Personales en Posesión de los Particulares, Contratos Express, S. de
          R.L. de C.V., pone a disposición de sus usuarios el presente Aviso de
          Privacidad:
        </p>
        <br />
        <h5 className="text-gray-700 font-bold">
          I. Identidad y domicilio del responsable de la protección de datos
          personales
        </h5>{' '}
        <p>
          Contratos Express, S. de R.L. de C.V., con domicilio en la Ciudad de
          México, es responsable del tratamiento de sus datos personales
          conforme a las finalidades señaladas en este aviso.
        </p>
        <br />
        <h5 className="text-gray-700 font-bold">
          II. Datos personales recabados
        </h5>{' '}
        <p>
          Para llevar a cabo las finalidades descritas en la siguiente sección,
          se podrán recabar los siguientes datos personales:
        </p>
        <br />
        <ol className="list-decimal pl-5 space-y-2">
          <li>Datos de contacto (correo electrónico y teléfono).</li>
          <li>Datos de identificación (nombre).</li>
          <li>
            Datos de facturación, en el caso de que solicite factura (RFC,
            código postal, nombre completo o razón social, régimen Fiscal en que
            tributen conforme a la Ley del ISR).
          </li>
        </ol>
        <p className="underline">No recabamos datos personales sensibles.</p>
        <br />
        <h5 className="text-gray-700 font-bold">
          III. Finalidades del tratamiento de datos personales
        </h5>{' '}
        <p>
          Los datos personales que recabamos serán utilizados para las
          siguientes finalidades:
        </p>
        <br />
        <ol className="space-y-4 pl-5">
          <li className="list-decimal">
            <strong>Finalidades Primarias</strong>{' '}
            <span className="underline">
              (necesarias para el servicio solicitado):
            </span>
            <ul className="list-inside list-circle pl-5 space-y-2">
              <li>Contacto, aclaraciones y comunicación.</li>
              <li>Facturación y procesamiento de pagos.</li>
              <li>Envío de productos y servicios contratados.</li>
              <li>
                Establecimiento de relaciones comerciales y contractuales.
              </li>
            </ul>
          </li>
          <li className="list-decimal">
            <strong>Finalidades Secundarias</strong>
            <span className="underline">
              (no necesarias, pero que permiten mejorar nuestro servicio):
            </span>
            <ul className="list-inside list-circle pl-5 space-y-2">
              <li>
                Envío de información promocional sobre productos y servicios.
              </li>
              <li>
                Realización de encuestas de satisfacción y estudios de mercado.
              </li>
            </ul>
          </li>
        </ol>
        <p>
          Si usted no desea que sus datos sean utilizados para las finalidades
          secundarias, puede manifestar su negativa enviando una solicitud al
          correo electrónico:{' '}
          <a
            className="text-blue-800 font-normal"
            href="mailto:contacto@contratos-express.com"
          >
            contacto@contratos-express.com
          </a>
          . La negativa a estas finalidades no afectará la prestación de los
          servicios contratados.
        </p>
        <br />
        <h5 className="text-gray-700 font-bold">
          IV. Transferencias de datos personales
        </h5>{' '}
        <p>
          Sus datos personales podrán ser compartidos con prestadores de
          servicios contratados por Contratos Express, S. de R.L. de C.V. para
          apoyar en la gestión, desarrollo y entrega de servicios contratados,
          asegurando la confidencialidad de sus datos conforme a la legislación
          mexicana.
        </p>
        <br />
        <p>
          No se realizarán transferencias de datos personales que requieran su
          consentimiento, excepto en los casos previstos por la ley.
        </p>
        <br />
        <h5 className="text-gray-700 font-bold">
          V. Opciones y medios para limitar el uso o divulgación de sus datos o
          negativa del consentimiento
        </h5>{' '}
        <p>
          Si usted desea limitar el uso o divulgación de sus datos personales o
          revocar el consentimiento que nos haya otorgado, puede hacerlo
          enviando su solicitud al siguiente correo electrónico:{' '}
          <a
            href="mailto:contacto@contratos-express.com"
            className="text-blue-800 font-normal"
          >
            contacto@contratos-express.com
          </a>
          . En un plazo máximo de 20 días hábiles le responderemos su solicitud.
        </p>
        <br />
        <h5 className="text-gray-700 font-bold">VI. Fundamento legal</h5>{' '}
        <p>
          El tratamiento de tus datos personales se realiza con fundamento en
          los artículos 102 y 103; 456, inciso g), fracción IV; 458, párrafos 1
          y 2; 459, párrafo 1, inciso c); 465, párrafos 3, 4, 5, 6, 7, 8 y 9;
          466, 467, 468, 469, 470, 471, 472, 473, de la Ley General de
          Instituciones y Procedimientos Electorales; 71 del Reglamento Interior
          del Instituto Nacional Electoral, y 10; 14, punto 3, fracción III; 16,
          puntos 7 y 8; 29, punto 2, inciso d), del Reglamento de Quejas y
          Denuncias del Instituto Nacional Electoral.
        </p>
        <br />
        <h5 className="text-gray-700 font-bold">
          VII. Derechos ARCO (Acceso, Rectificación, Cancelación y Oposición)
        </h5>{' '}
        <p>
          Usted tiene el derecho de acceder, rectificar, cancelar u oponerse al
          tratamiento de sus datos personales. Para ejercer cualquiera de estos
          derechos, puede presentar su solicitud enviando un correo a{' '}
          <a
            href="mailto:contacto@contratos-express.com"
            className="text-blue-800 font-normal"
          >
            contacto@contratos-express.com
          </a>
          , especificando el derecho que desea ejercer y proporcionando la
          información necesaria para su identificación. Se le dará respuesta
          dentro de un plazo de 20 días hábiles.
        </p>
        <br />
        <h5 className="text-gray-700 font-bold">
          VIII. Cambios al aviso de privacidad
        </h5>{' '}
        <p>
          Este aviso de privacidad puede ser modificado por cambios legales, de
          nuestros productos y servicios, o por nuestras políticas internas. En
          caso de realizar cambios sustanciales, se le informará a través de
          nuestro sitio web o mediante el último correo electrónico
          proporcionado. Puede consultar en cualquier momento la versión
          actualizada del aviso en{' '}
          <Link to="/privacy-policy" className="text-blue-800 font-normal">
            www.contratos-express.com/privacy-policy
          </Link>
          .
        </p>
        <br />
        <h5 className="text-gray-700 font-bold">
          IX. Uso de cookies y tecnologías de rastreo
        </h5>{' '}
        <p>
          En nuestro sitio web utilizamos cookies y tecnologías similares para
          mejorar su experiencia de navegación. Estas herramientas nos permiten
          obtener datos como la dirección IP, tipo de navegador y secciones
          visitadas. Usted puede deshabilitar las cookies a través de las
          configuraciones de su navegador, aunque esto podría afectar algunas
          funcionalidades del sitio.
        </p>
        <br />
        <h5 className="text-gray-700 font-bold">
          X. Medidas de seguridad y plazo de conservación
        </h5>{' '}
        <p>
          Contratos Express, S. de R.L. de C.V. ha implementado medidas de
          seguridad administrativas, técnicas y físicas para proteger sus datos
          personales contra pérdida, uso indebido, acceso no autorizado o
          divulgación. Conservaremos sus datos personales durante el tiempo
          necesario para cumplir con las finalidades para las que fueron
          recabados y de acuerdo con la ley.
        </p>
        <br />
      </section>
      <footer className="w-full flex justify-center mt-10">
        <Link to="/" className="text-blue-700 font-medium underline">
          Volver al inicio
        </Link>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
