import { Facebook, Instagram, Linkedin, Shield, Twitter } from 'lucide-react';
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-300 py-12">
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-4 gap-8">
          <div>
            <div className="flex items-center mb-4">
              <span className="ml-2 text-xl font-bold text-white">
                Contratos Express
              </span>
            </div>
            <p className="text-gray-400">
              Tu solución integral para la gestión de contratos legales
            </p>
          </div>
          <div>
            <h4 className="text-white font-semibold mb-4">Enlaces Rápidos</h4>
            <ul className="space-y-2">
              <li>
                <a href="#" className="hover:text-blue-400 transition">
                  Inicio
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-blue-400 transition">
                  Servicios
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-blue-400 transition">
                  Precios
                </a>
              </li>
              <li>
                <a
                  href="mailto:contacto@contratos-express.com"
                  className='className="hover:text-blue-400 transition"'
                >
                  Contacto
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h4 className="text-white font-semibold mb-4">Legal</h4>
            <ul className="space-y-2">
              <li>
                <Link
                  to="/terms-and-conditions"
                  className="hover:text-blue-400 transition"
                >
                  Términos y Condiciones
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy-policy"
                  className="hover:text-blue-400 transition"
                >
                  Política de Privacidad
                </Link>
              </li>
              <li>
                <a href="#" className="hover:text-blue-400 transition">
                  Aviso Legal
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h4 className="text-white font-semibold mb-4">Síguenos</h4>
            <div className="flex space-x-4">
              <a
                href="https://www.facebook.com/profile.php?id=61564893953790"
                className="hover:text-blue-400 transition"
              >
                <Facebook className="h-6 w-6" />
              </a>
              <a href="#" className="hover:text-blue-400 transition">
                <Twitter className="h-6 w-6" />
              </a>
              <a
                href="https://www.linkedin.com/company/contratos-express/"
                className="hover:text-blue-400 transition"
              >
                <Linkedin className="h-6 w-6" />
              </a>
              <a href="#" className="hover:text-blue-400 transition">
                <Instagram className="h-6 w-6" />
              </a>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-800 mt-12 pt-8 text-center">
          <p className="text-gray-400">
            © {new Date().getFullYear()} Contratos Express. Todos los derechos
            reservados.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
